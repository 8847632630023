<template>
  <div class="manage-project-booking-detail">
    <div>
      <booking-detail
        :getBookingDetailAPI="bookingAPI.getBookingDetail"
        :downloadFileAPI="bookingAPI.downloadFile"
        :approveBookingAPI="bookingAPI.approveBooking"
        :rejectBookingAPI="bookingAPI.rejectBooking"
        :cancelBookingAPI="bookingAPI.cancelBooking"
        listingRouteName="ManageProjectBooking"
      ></booking-detail>
    </div>
  </div>
</template>

<script>
import BookingDetail from "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingDetail";
import { projectBooking as bookingAPI } from "@/api";

export default {
  components: { BookingDetail },
  mixins: [],
  props: {},
  data: function () {
    return {
      bookingAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
